import '../styles/home_bottom.css'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/owl.carousel';
import './shared';
import './plugins/vote';

const _dir = $('html').attr('dir');

((
    ($) => {

        const onInitialized = (e) => {
            let _owlCarousel = e.target;
            $(_owlCarousel).find('.owl-dot').each((index, el) => {
                $(el).find('span').text((++index));
            })
        };

        $('.main--slider-items.owl-carousel').owlCarousel({
            rtl: (_dir === 'rtl'),
            items: 1,
            autoplay: true,
            loop: true,
            autoplayHoverPause: true,
            dots: true,
            dotsEach: true,
            nav: false,
            margin: 0,
            onInitialized: onInitialized,
        });


        $('.list3.owl-carousel').owlCarousel({
            rtl: (_dir === 'rtl'),
            items: 5,
            autoplay: true,
            loop: true,
            autoplayHoverPause: true,
            dots: true,
            nav: false,
            margin: 0,
        });

    }
)(jQuery));