import './plugins/load_images';

((
    ($) => {

        const _monthNames = ["january", "february", "march", "april", "may", "june",
            "july", "august", "september", "october", "november", "december"
        ];
        let _dateHolder = $('.js-time-holder');

        function showTime() {
            let date = new Date();
            date.toLocaleString('ar-SA', {timeZone: 'Asia/Jerusalem'})

            let h = date.getHours(); // 0-23
            let m = date.getMinutes(); // 0-59
            let _month = date.getMonth(); // 0-11
            let _day = date.getDate(); // 1-31
            let _year = date.getFullYear();

            let _monthName = _monthNames[_month];
            _monthName = window.months[_monthName];

            let session = window.time.am;
            if (h > 12) {
                session = window.time.pm;
                h -= 12;
            }

            h = (h < 10) ? "0" + h : h;
            m = (m < 10) ? "0" + m : m;

            let _fullString = h + ":" + m + " " + session + ", ";
            _fullString += _day + " " + _monthName + " " + _year;
            _dateHolder.text(_fullString);
            setTimeout(showTime, 30000);
        }

        if (_dateHolder.length) {
            showTime();
        }

        $('.js-weather').change(function () {
            let _targetId = $(this).val();
            let _target = $(`#${_targetId}`);
            if (_target.length) {
                if (_target.hasClass('active')) {
                    return;
                }
                $('.header--tab-content.active').removeClass('active');
                _target.addClass('active');
            }
        });

        $('[data-open-popup]').click(function (e) {
            e.preventDefault();
            let _popUpName = $(this).data('open-popup');
            let _popUp = $(`[data-popup="${_popUpName}"]`);
            if (_popUp.length) {
                _popUp.addClass('active');
                $('body').css('overflow', 'hidden');
            }
        });

        $('[data-close-popup]').click(function (e) {
            e.preventDefault();
            let _popUpName = $(this).data('close-popup');
            let _popUp = $(`[data-popup="${_popUpName}"]`);
            if (_popUp.length) {
                _popUp.removeClass('active');
                $('body').css('overflow', 'visible');
            }
        });

        $('.js-tab').click(function (e) {
            e.preventDefault();
            let _clicked = $(this);
            if (_clicked.hasClass('active')) {
                return;
            }
            let _wrapper = _clicked.closest('.js-tabs-w')
            let _targetId = _clicked.data('target-id');
            if (typeof _targetId !== 'undefined') {
                let _target = _wrapper.find(`#${_targetId}`);
                if (_target.length) {
                    _wrapper.find('.js-tab.active').removeClass('active');
                    _wrapper.find('.js-tab-content.active').removeClass('active');
                    _clicked.addClass('active');
                    _target.addClass('active');
                }
            }
        });

        let _header = $('header');
        let _body = $('body');
        const _fixHeader = () => {
            let _headerHeight = _header.outerHeight();
            let _scrollAmount = $(document).scrollTop();
            if (_scrollAmount > _header.outerHeight()) {
                if (_body.hasClass('fixed--header')) {
                    return;
                }
                _body.css('padding-top', _headerHeight);
                _body.addClass('fixed--header');
            } else {
                if (!_body.hasClass('fixed--header')) {
                    return;
                }
                _body.css('padding-top', 0);
                _body.removeClass('fixed--header');
            }
        };

        _fixHeader();
        $(window).scroll(function () {
            _fixHeader();
        });

    }
)(jQuery));