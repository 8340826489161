import {autoChangeBanners} from "./banners";

((
    ($) => {

        function check_webp_feature(feature, callback) {
            var kTestImages = {
                lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
                lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
                alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
                animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
            };
            var img = new Image();
            img.onload = function () {
                var result = (img.width > 0) && (img.height > 0);
                callback(feature, result);
            };
            img.onerror = function () {
                callback(feature, false);
            };
            img.src = "data:image/webp;base64," + kTestImages[feature];
        }
        const callback = (feature, result) => {
            if (result !== false) {
                window.next_generation_img = true;
            }
        };
        if (typeof window.next_generation_compression !== 'undefined' && !window.next_generation_img) {
            check_webp_feature(window.next_generation_compression, callback);
        }

        var _lazyLoad = function (target) {
            // if(window.next_generation_img){
            //     target.setAttribute('src', 'https://www.baladna.ps/test1.webp');
            //     return;
            // }
            if (target.classList.contains('lazyloaded')) {
                return;
            }
            if (!target.classList.contains('lazyload')) {
                // return;
            }

            var canIntersection = typeof window.IntersectionObserver === 'function' || 'IntersectionObserver' in window;
            if (!canIntersection) {
                var src = target.getAttribute('data-src');
                var srcset = target.getAttribute('data-srcset');
                if ($(target).hasClass('lazyload')) {
                    $(target).removeClass('lazyload');
                }
                if (typeof srcset !== 'undefined' && srcset && srcset.length) {
                    target.setAttribute('srcset', srcset);
                }
                if (typeof src !== 'undefined' && src && src.length) {
                    target.setAttribute('src', src);
                }
                return;
            }
            var io = new IntersectionObserver(function (entries, observer) {

                for (var _j = 0; _j < entries.length; _j++) {
                    var entry = entries[_j];

                    if (entry.isIntersecting) {
                        const images = [];
                        if (entry.target.tagName.toLowerCase() !== 'img' && entry.target.tagName.toLowerCase() !== 'iframe') {
                            $.each($(entry.target).find('img'), function (_i, _img) {
                                images.push(_img);
                            });
                        } else {
                            images.push(entry.target);
                        }

                        $.each(images, function (idx, img) {
                            var src = img.getAttribute('data-src');
                            var srcset = img.getAttribute('data-srcset');

                            if (entry.target.tagName.toLowerCase() === 'iframe') {
                                if ($(target).hasClass('lazyload')) {
                                    $(target).removeClass('lazyload');
                                }

                                setTimeout(function () {
                                    if (typeof srcset !== 'undefined' && srcset && srcset.length) {
                                        img.setAttribute('srcset', srcset);
                                    }
                                    if (typeof src !== 'undefined' && src && src.length) {
                                        img.setAttribute('src', src);
                                    }
                                }, 1000)
                            } else {
                                if (typeof srcset !== 'undefined' && srcset && srcset.length) {
                                    img.setAttribute('srcset', srcset);
                                }
                                if (typeof src !== 'undefined' && src && src.length) {
                                    img.setAttribute('src', src);
                                }

                                img.onload = function () {
                                    $(this).removeClass('lazyload');
                                    $(this).addClass('lazyloaded');
                                }
                            }

                            setTimeout(function () {
                                $(img).removeClass('lazyload');
                                $(img).removeClass('lz-init');
                            }, 1000);

                        });
                        observer.disconnect();
                    }
                }
            }, {
                threshold: [0, 1]
            });

            $(target).addClass('lz-init');
            io.observe(target)
        };

        function loadImages() {
            var targetImgs = document.querySelectorAll('img.lazyload:not(.lz-init), iframe.lazyload:not(.lz-init)');
            if (targetImgs.length) {
                for (var _i = 0; _i < targetImgs.length; _i++) {
                    _lazyLoad(targetImgs[_i]);
                }
            }
        }

        loadImages();

        $.each($('.bnrs-group'), function (index, group) {
            let timeout = parseInt($(group).data('timeout'), 10);

            if (typeof timeout !== 'number' || isNaN(timeout)) {
                timeout = 10;
            }
            if (timeout < 5) {
                timeout = 5;
            }
            timeout = timeout * 1000;

            // setInterval(function () {
            autoChangeBanners($(group)).then(function () {
                setTimeout(loadImages, 2000);
                // loadImages();
            });
            // }, timeout)

        });

    }
)(jQuery));