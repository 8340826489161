function _shuffle_array(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export const autoChangeBanners = (groupEl = null) => {

    return new Promise(function (resolve, reject) {
        try {
            if (typeof window.site_banners === 'undefined') {
                reject();
                return;
            }
            const changeGroupBanners = function (_group) {
                const bid = _group.data('bid');
                if (typeof bid === 'undefined' || !bid || typeof window.site_banners[bid] === 'undefined' || window.site_banners[bid].length <= 1) {
                    return;
                }

                let groupBanners = [];
                window.site_banners[bid] = _shuffle_array(window.site_banners[bid]);

                $.each(window.site_banners[bid], function (index, banner) {
                    groupBanners.push($.extend({}, banner));
                });
                const limit = _group.data('limit');
                // const display = _group.data('display');
                // groupBanners = _shuffle_array(groupBanners);
                for (let i = 0; i < limit; ++i) {
                    // groupBanners = _shuffle_array(groupBanners);
                    var item = _group.find('.bnr__item').eq(i);
                    if (item.length) {
                        const banner = groupBanners.shift();
                        var html = banner.html;
                        item.html(banner.html);
                    }
                }
            };

            if (groupEl && typeof groupEl !== 'undefined' && $(groupEl).length) {
                changeGroupBanners(groupEl);
            } else {
                $.each($('.bnrs-group'), function (index, group) {
                    changeGroupBanners($(group));
                });
            }

            resolve();
        } catch (e) {
            console.log('loading banner failed!', e);
            reject();
        }
    });
};