((
    ($) => {

        let _isSending = false;
        $('.js-vote').submit(function (e) {
            e.preventDefault();
            let _form = $(this);
            let _url = _form.data('action');
            let _answerId = _form.find('input[name="vote_answer"]:checked').val();
            if (!_isSending) {
                $.ajax({
                    url: _url + _answerId,
                    method: 'POST',
                    dataType: 'JSON',
                    beforeSend: function () {
                        _isSending = true;
                        _form.addClass('is-sending');
                        _form.find('.vote-msgs').empty();
                    },
                    success: function (response) {
                        let _msgs = response.messages;
                        let _class = '';
                        if (response.status === 'success') {
                            _class = 'alert-success';
                        } else {
                            _class = 'alert-danger';
                        }
                        $.each(_msgs, function (e, i) {
                            _form.find('.vote-msgs').append('<div class="alert ' + _class + '">' + i + '</div>');
                        });
                    },
                    error: function (response) {

                    },
                    complete: function () {
                        _isSending = false;
                        _form.removeClass('is-sending');
                    },
                });
            }
        });

    }
)(jQuery));